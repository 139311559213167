body { color: var(--clr-68); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: #000000;
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 30px;

@media #{$medium-up} {
font-size: 50px;

}
}
h2 {
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
color: var(--clr-68);
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 35px;

}
}
h5 {
color: var(--clr-68);
font-family: 'Montserrat';
font-weight: 700;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 26px;

}
@media #{$large-up} {
font-size: 30px;

}
}
summary {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 35px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
p { padding-bottom: 1em; }
a {color: var(--clr-68);}
a:hover {color: var(--clr-69);}
/* Homepage BG:2 */
.MES2 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://oceaniasciences.com/img/10/4');
background-position: top right;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
 }
.MES2 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://oceaniasciences.com/img/10/4');
background-position: top right;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
h1.MEC2, h2.MEC2, h3.MEC2, h4.MEC2, h5.MEC2, h6.MEC2 { color: #ffffff;
 }
 }
a.MEC2 { color: #ffffff;
 }
cite.MEC2{
color: #ffffff;
}
/* Login button:3 */
.MES3 {
background-color: transparent;
&:hover {background-color: var(--clr-70);}
color: #ffffff;
padding: 5px 20px;

border-width: 1px;
border-style: solid;
border-color: #ffffff;
 }
/* POPUP bg:4 */
.MES4 {
color: var(--clr-68);
min-height:300px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 600px;};
 }
.MES4 {
color: var(--clr-68);
min-height:300px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 600px;};
h1.MEC4, h2.MEC4, h3.MEC4, h4.MEC4, h5.MEC4, h6.MEC4 { color: var(--clr-68);
 }
 }
cite.MEC4{
color: var(--clr-68);
}
/* Spacer top:6 */
.MES6 {
padding: 15px;

 }
/* Login Button:7 */
.MES7 {
background-color: var(--clr-71);
&:hover {background-color: var(--clr-72);}
color: #ffffff;
&:hover { color: #ffffff;}
font-size: 16.8px;
@media #{$medium-up} {
font-size: 19.2px;
};
 }
/* White transparent:8 */
.MES8 {
background-color: var(--clr-70);
&:hover, &.hover { background-color: var(--clr-73);}
color: #ffffff;
padding: 15px;

@media #{$large-up} {
padding: 20px;

}
 }
.MES8 {
background-color: var(--clr-70);
&:hover, &.hover { background-color: var(--clr-73);}
color: #ffffff;
padding: 15px;

@media #{$large-up} {
padding: 20px;

}
h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: #ffffff;
 }
 }
cite.MEC8{
color: #ffffff;
}
/* image panel:9 */
.MES9 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-70);}
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://oceaniasciences.com/img/279/30');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}min-height:200px;
@media #{$medium-up} {
min-height: 600px;};
@media #{$large-up} {
min-height: 600px;};
 }
.MES9 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-70);}
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://oceaniasciences.com/img/279/30');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}min-height:200px;
@media #{$medium-up} {
min-height: 600px;};
@media #{$large-up} {
min-height: 600px;};
 }
/* White transparent 2:10 */
.MES10 {
background-color: var(--clr-70);
&:hover, &.hover { background-color: var(--clr-70);}
color: #ffffff;
padding: 15px;

@media #{$large-up} {
padding: 20px;

}
 }
.MES10 {
background-color: var(--clr-70);
&:hover, &.hover { background-color: var(--clr-70);}
color: #ffffff;
padding: 15px;

@media #{$large-up} {
padding: 20px;

}
h1.MEC10, h2.MEC10, h3.MEC10, h4.MEC10, h5.MEC10, h6.MEC10 { color: #ffffff;
 }
 }
cite.MEC10{
color: #ffffff;
}
/* Shade 1:11 */
.MES11 {
background-color: var(--clr-69);
 }
/* Shade 1:12 */
.MES12 {
background-color: var(--clr-69);
 }
.MES12 {
background-color: var(--clr-69);
 }
/* Shade 2:13 */
.MES13 {
background-color: var(--clr-74);
 }
/* Shade 2:14 */
.MES14 {
background-color: var(--clr-74);
 }
.MES14 {
background-color: var(--clr-74);
 }
/* portal :15 */
nav.responsive-menu {
& .menu-item.MEC15, & .menu-item.MEC15 > div.menu-item-wrap{ & > a.MEC15, & > i{color: #000000;
text-align: center;
}
  }
& .menu-item.MEC15 { border:0;

 border-bottom-width: 1px; }
& .sub-menu{ .sub-menu {}}

 }
/* Header Login:16 */
.MES16 {
background-color: transparent;
&:hover {background-color: transparent;}
color: var(--clr-68);
&:hover { color: var(--clr-68);}
padding: 0;

 }
